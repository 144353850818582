import styled from "styled-components";
import { Animation } from "libs/purple-ui";

export const Skeleton = styled.span`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "1rem"};
  max-width: ${({ maxWidth }) => maxWidth || "100%"};
  background-color: ${({ theme, primaryColor }) =>
    primaryColor ?? theme.colors.grey_0};
  background-image: ${({
    theme,
    primaryColor,
    secondaryColor,
  }) => `linear-gradient(
    90deg,
    ${primaryColor ?? theme.colors.grey_0} 25%,
    ${secondaryColor ?? theme.colors.white_2} 50%,
    ${primaryColor ?? theme.colors.grey_0} 75%
  )`};
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;

  ${Animation.InfiniteWaveAnimation}
`;
