import React, { Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IconButton } from "@material-ui/core";

import { Creators as ModalActions } from "store/ducks/modal";

import { Spinner } from "libs/purple-ui";
import CloseIcon from "@material-ui/icons/Close";

import * as S from "./styles";

const Modal = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { show, title, content, size, onClose } = useSelector(
    (state) => state.modal
  );

  useEffect(() => {
    return () => dispatch(ModalActions.hide());
  }, []);

  const handleClose = () => {
    dispatch(ModalActions.hide());
    if (typeof onClose === "function") {
      onClose?.();
    }
  };

  const modalStateClassName = show ? "show" : "hidden";

  return (
    <S.Overlay className={modalStateClassName}>
      <S.Wrapper className={modalStateClassName}>
        {show && (
          <S.Content size={size}>
            {!!title && (
              <S.Header>
                <h4 style={{ marginBottom: 0 }}>{title}</h4>
                <IconButton
                  onClick={handleClose}
                  edge="end"
                  aria-label="close-modal"
                >
                  <CloseIcon />
                </IconButton>
              </S.Header>
            )}
            <S.Body>
              <Suspense
                fallback={
                  <S.Loading size={size}>
                    <Spinner
                      color={[theme.colors.primary_6, theme.colors.primary_2]}
                      size="3rem"
                    />
                  </S.Loading>
                }
              >
                {content}
              </Suspense>
            </S.Body>
          </S.Content>
        )}
      </S.Wrapper>
    </S.Overlay>
  );
};

export default Modal;
