import styled from "styled-components";
import colors from "assets/colors";

export const Container = styled.div``;

const SIZES = {
  sm: `calc(328px - 2 * 1rem)`,
  md: `calc(528px - 2 * 1rem)`,
  lg: `calc(828px - 2 * 1rem)`,
  xl: `calc(1168px - 2 * 1rem)`,
};

const SIZES_HEIGHT_LOADING = {
  sm: `calc(164px - 2 * 1rem)`,
  md: `calc(264px - 2 * 1rem)`,
  lg: `calc(414px - 2 * 1rem)`,
  xl: `calc(584px - 2 * 1rem)`,
};

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100vw;
  height: 100%;
  z-index: 1040;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0px;

  transition: all 0.2s ease-out;

  &.show {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.hidden {
    visibility: hidden;
    background-color: transparent;
  }
`;

export const Wrapper = styled.div`
  z-index: 1050;
  display: flex;
  align-items: center;

  width: 100%;
  min-height: calc(100% - 3.5rem);
  margin: 1.75rem auto;
  inset: 0px;
  visibility: hidden;
  transform: translate(0, -50px);
  transition: transform 0.3s ease-out;

  &.show {
    transform: none;
    visibility: visible;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  grid-gap: 1rem;

  padding: 12px 24px 0px 22px;
  justify-content: space-between;
  color: ${colors.black};

  > h4 {
    margin-bottom: 0;
    font-weight: 400;
    color: ${colors.black};
    font: 1rem/1.255rem "Muli Bold", sans-serif;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ size }) => SIZES[size] || "100%"};
  position: relative;

  background: #fff;
  border-radius: 1rem;
  margin-bottom: 1rem;
`;

export const Body = styled.div`
  padding: 0px 22px 22px;
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -3.75rem;
  height: ${({ size }) => SIZES_HEIGHT_LOADING[size] || "100%"};
`;
