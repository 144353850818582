import React from "react";
import { useTheme } from "styled-components";

import { Text } from "libs/purple-ui";

const ErrorMessage = ({ error = "" }) => {
  const theme = useTheme();
  return (
    error && (
      <Text color={theme.colors.danger_4} size="small">
        {error}
      </Text>
    )
  );
};

export default ErrorMessage;
