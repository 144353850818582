import mainService from "services/mainService";

/**
 * @description Obtém a lista de departamentos
 */
export const getDepartaments = () => {
  return mainService.get("/departments/");
};

/**
 * @description Ativa ou desativa uma subconta de estabelecimento
 * @param {number} estabPk - PK do estabelecimento
 * @param {number} accountPk - PK da conta a ser desativada
 * @example toggleSubAccount({
 *  estabPk: 1,
 *  accountPk: 2,
 * })
 */
export const toggleSubAccount = ({ estabPk, accountPk }) => {
  return mainService.delete(
    `v2/establishment/${estabPk}/subaccounts/${accountPk}`
  );
};

/**
/**
 * @description Cria uma nova subconta para o estabelecimento
 * @param {number} estabPk - PK do estabelecimento
 * @param {Object} body - Corpo da requisição com os dados da subconta a ser criada
 * @param {Object} body.user - Dados do usuário da subconta
 * @param {string} body.user.first_name - Nome do usuário
 * @param {string} body.user.last_name - Sobrenome do usuário
 * @param {string} body.user.email - Email do usuário
 * @param {string} body.user.username - Número de telefone do usuário, formatado com o padrão "5599999999999"
 * @param {string} body.user.password - Senha do usuário
 * @param {number} body.department - Departamento da subconta
 * @example createSubAccount({
 *  estabPk: 1,
 *  body: {
 *    user: {
 *      first_name: "João",
 *      last_name: "Silva",
 *      email: "joao.silva@example.com",
 *      username: "5599888777666",
 *      password: "senha123",
 *    },
 *    department: 1
 *  },
 * })
 */
export const createSubAccount = ({ estabPk, body }) => {
  return mainService.post(`v2/establishment/${estabPk}/subaccounts`, body, {
    headers: {
      "notifications-front-banned": true,
    },
  });
};

/**
 * @description Atualiza os dados de uma subconta existente
 * @param {number} estabPk - PK do estabelecimento
 * @param {number} accountPk - PK da conta a ser atualizada
 * @param {Object} body - Corpo da requisição com os novos dados da subconta
 * @param {Object} body.user - Dados do usuário da subconta
 * @param {string} body.user.first_name - Nome do usuário
 * @param {string} body.user.last_name - Sobrenome do usuário
 * @param {string} body.user.email - Email do usuário
 * @param {string} body.user.username - Número de telefone do usuário, formatado com o padrão "5599999999999"
 * @param {number} body.department - Departamento da subconta
 * @example updateSubAccount({
 *  estabPk: 1,
 *  accountPk: 2,
 *  body: {
 *    user: {
 *      first_name: "João",
 *      last_name: "Silva",
 *      email: "joao.silva@example.com",
 *      username: "5599888777666",
 *    },
 *    department: 1
 *  },
 * })
 */
export const updateSubAccount = ({ estabPk, body, accountPk }) => {
  return mainService.patch(
    `v2/establishment/${estabPk}/subaccounts/${accountPk}`,
    body,
    {
      headers: {
        "notifications-front-banned": true,
      },
    }
  );
};
