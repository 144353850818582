import mainService from "services/mainService";

/**
 * @description Busca a lista de profissionais bloqueados em um estabelecimento
 * @param {Object} params
 * @param {number} params.establishmentId
 * @param {number} params.page
 * @param {number} params.pageSize
 * @returns {Promise}
 * @example getBlockedWorkers({ establishmentId: 1, page: 1, pageSize: 10 });
 */
export const getBlockedWorkers = async ({
  establishmentId,
  page,
  pageSize,
}) => {
  // TODO
  return mainService.get(
    `v2/establishment/${establishmentId}/blocked_workers?page=${page}&page_size=${pageSize}`
  );
};

/**
 * @description Desbloqueia um ou múltiplos profissionais em um estabelecimento
 * @param {Object} params
 * @param {number} params.userId
 * @param {string} params.user - bigcompany | establishment
 * @comment Supervisor não deve utilizar a rota como bigcompany, somente como establishment
 * @comment A rota bigcompany realiza o desbloqueio dos workerIds em todas as unidades
 * @param {Array<number>} params.workerIds
 * @returns {Promise}
 * @example unblockWorkers({ userId: 1, workerIds: [1, 2, 3] });
 */
export const unblockWorkers = async ({ userId, user, workerIds }) => {
  return mainService.post(`v2/${user}/${userId}/remove_block_many_workers`, {
    workers_ids: workerIds,
  });
};

/**
 * @description Remove um profissional de todas as unidades
 * @param {Object} params
 * @param {number} params.userId
 * @param {number} params.workerId
 * @returns {Promise}
 * @example removeWorkerAllUnits({ userId: 1, workerId: 1 });
 */
export const removeWorkerAllUnits = async ({ userId, workerId }) => {
  return mainService.delete(
    `v2/bigcompany/${userId}/pool/${workerId}/detach-bases`
  );
};

/**
 * @description Remove um profissional de uma unidade
 * @param {Object} params
 * @param {number} params.userId
 * @param {number} params.workerId
 * @returns {Promise}
 * @example removeWorkerUnit({ userId: 1, workerId: 1 });
 */
export const removeWorkerUnit = async ({ userId, workerId }) => {
  return mainService.delete(`v2/establishment/${userId}/pool/${workerId}`);
};
