import React from "react";
import { useTheme } from "styled-components";
import PropTypes from "prop-types";

import { Text } from "libs/purple-ui";

import { Icon } from "@iconify/react";

import * as S from "./styles";

/**
 * Componente Checkbox
 *
 * ## Parâmetros
 * @param {boolean} checked - Define se o checkbox está marcado ou não (default: false)
 * @param {function} onChange - O callback chamado quando o estado do checkbox é alterado
 * @param {boolean} disabled - Define se o checkbox está desabilitado (default: false)
 * @param {string} text - O texto exibido junto ao checkbox (default: "")
 * @param {string} description - Uma descrição opcional para o checkbox (default: "")
 * @param {string} error - Descrição do erro (default: "")
 * @param {boolean} clickable - Define se o checkbox é clicável (default: true)
 * @param {object} style - Estilos adicionais para o componente (default: {})
 *
 * ## Exemplo de uso
 * @example
 * <Checkbox
 *  checked={true}
 *  onChange={(e) => console.log('Checkbox marcado')}
 *  disabled={false}
 *  text="Aceito os termos e condições"
 *  error="Algo deu errado",
 *  description="Ao marcar esta opção, você concorda com os termos e condições"
 *  clickable={true}
 * />
 *
 * @returns {node} - Retorna um elemento de caixa de seleção
 */
export const Checkbox = ({
  checked = false,
  onChange = () => {},
  disabled = false,
  text = "",
  description = "",
  error = "",
  clickable = true,
  style = {},
}) => {
  const theme = useTheme();

  const handleClick = () => {
    if (!disabled && clickable) {
      onChange();
    }
  };
  return (
    <S.ContainerCheckbox disabled={disabled} style={style}>
      <S.CheckboxWrapper>
        <S.Checkbox
          onClick={handleClick}
          disabled={disabled}
          checked={checked}
          clickable={clickable}
        >
          {checked && (
            <Icon
              icon="f7:checkmark-alt"
              color={disabled ? theme.colors.grey_2 : theme.colors.white_pure}
            />
          )}
        </S.Checkbox>
        {text && (
          <S.Text
            disabled={disabled}
            clickable={clickable}
            onClick={handleClick}
          >
            {text}
          </S.Text>
        )}
      </S.CheckboxWrapper>
      {description && (
        <S.ContainerDescription>
          <Text color={theme.colors.grey_3}>{description}</Text>
        </S.ContainerDescription>
      )}
      {error && (
        <Text color={theme.colors.danger_4} size="small">
          {error}
        </Text>
      )}
    </S.ContainerCheckbox>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.string,
  clickable: PropTypes.bool,
  style: PropTypes.object,
};
