import mainService from "services/mainService";

/**
 * --------------------------------------------------
 * @description Pega os dados do dashboard
 * @param {number} estabPk
 * @example
 * getDashboardData({estabPk = 988})
 */
export const getDashboardData = ({ estabPk } = {}) => {
  const url = `v2/establishment/${estabPk}/metrics_to_current_month`;
  return mainService.get(url);
};

/**
 * --------------------------------------------------
 * @description Pega o histórico do dashboard
 * @param {number} estabPk
 * @param {object} params
 * @param {number} page
 * @param {number} pageSize
 * @example
 * getDashboardHistory({estabPk = 988, page = 1, pageSize = 10, params = ''})
 */
export const getDashboardHistory = ({
  estabPk,
  params,
  page,
  pageSize,
} = {}) => {
  let url = `v2/establishment/${estabPk}/history_metrics_by_month?page=${page}&page_size=${pageSize}${params}`;

  return mainService.get(url);
};

/**
 * --------------------------------------------------
 * @description Pega os dados de análise dos custos do dashboard
 * @param {number} estabPk
 * @example
 * getDashboardCostAnalysis({estabPk = 988})
 */
export const getDashboardSummary = ({ estabPk } = {}) => {
  const url = `v2/establishment/${estabPk}/highest_metrics_by_month_on_history`;
  return mainService.get(url, {
    headers: {
      "notifications-front-banned": true,
    },
  });
};

/**
 * --------------------------------------------------
 * @description Gera o relatório do dashboard
 * @param {number} estabPk
 * @param {object} body
 * @param {string} body.flag - Tipo de relatório (last_12_months, periods)
 * @param {string} body.starts_at - Data de início quando flag = periods (formato YYYY-MM-DD)
 * @param {string} body.ends_at - Data de fim quando flag = periods (formato YYYY-MM-DD)
 * @param {string} body.custom_email - Email personalizado
 * @example
 * exportDashboardData({
 *  estabPk = 988
 *  body = {
 *   flag: 'last_12_months',
 *   starts_at: '2021-01-01',
 *   ends_at: '2021-12-31',
 *   custom_email: 'ednaldo_pereira@email.com'
 * })
 */
export const exportDashboardData = ({
  estabPk,
  body = { flag, startsAt, endsAt, customEMail },
} = {}) => {
  const url = `v2/establishment/${estabPk}/metrics/report`;
  return mainService.post(url, { ...body });
};
