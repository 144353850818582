import common from "./_common.json";
import forms from "./_forms.json";
import validation from "./_validation.json";

import recurrenceCalendar from "./modules/_recurrenceCalendar.json";
import notification_center from "./modules/_notification_center.json";
import links from "./modules/_links.json";
import legend from "./modules/_legend.json";
import subscription from "./modules/_subscription.json";
import custom_value_per_role from "./modules/_custom_value_per_role.json";

import dropzone from "./components/_dropzone.json";
import enterScreenCards from "./components/_enterScreenCards.json";
import filter from "./components/_filter.json";
import search_nps from "./components/_search_nps.json";
import modal_extra_value_report from "./components/_modal_extra_value_report.json";
import modal_pro_rating_report from "./components/_modal_pro_rating_report.json";
import modal_search_report from "./components/_modal_search_report.json";
import balanceResume from "./components/_balanceResume.json";
import modalConfigJobFinalization from "./components/_modalConfigJobFinalization.json";
import modalConfigJobStart from "./components/_modalConfigJobStart.json";
import modalConfigRecurrence from "./components/_modalConfigRecurrence.json";
import modal_cancel_pending_worker_jobs from "./components/_modal_cancel_pending_worker_jobs.json";
import modal_subscription_invalid from "./components/_modal_subscription_invalid.json";
import modalPWA from "./components/_modalPWA.json";
import modalCustomRole from "./components/_modalCustomRole.json";
import modalBlockActions from "./components/_modal_block_actions.json";
import modalPreCreateVacancy from "./components/_modalPreCreateVacancy.json";
import modalVacancy from "./components/_modal_vacancy.json";
import modal_close_vacancy from "./components/_modal_close_vacancy.json";
import modal_disqualified_candidates from "./components/_modal_disqualified_candidates.json";
import modalBlockedWorkers from "./components/_modalBlockedWorkers.json";
import modal_worker_other_unit from "./components/_modal_worker_other_unit.json";
import modalRenewOffer from "./components/_modalRenewOffer.json";
import modalSubscriptionError from "./components/_modalSubscriptionError.json";
import modalExtendDepositExpiration from "./components/_modalExtendDepositExpiration.json";
import modalChangeDepositValue from "./components/_modalChangeDepositValue.json";

import login from "./pages/_login.json";
import create_account from "./pages/_create_account.json";
import forgot_password from "./pages/_forgot_password.json";
import new_password from "./pages/_new_password.json";
import dashboard from "./pages/_dashboard.json";
import modal_job from "./pages/_modal_agendamento.json";
import professional from "./pages/_profissionais.json";
import search from "./pages/_search.json";
import eduCloseer from "./pages/_eduCloseer.json";
import report from "./pages/_report.json";
import access from "./pages/_access.json";
import schedule from "./pages/_schedule.json";
import billing from "./pages/_billing.json";
import configProfile from "./pages/_config_profile.json";
import configAddress from "./pages/_config_address.json";
import configSideCards from "./pages/_config_sidecards.json";
import instruction from "./pages/_instruction.json";
import units from "./pages/_units.json";
import user_access from "./pages/_user_access.json";
import jobs from "./pages/_jobs.json";
import modal_base_empty from "./pages/_modal_base_empty.json";
import modal_transaction_details from "./pages/_modal_transaction_details.json";
import modal_add_question from "./pages/_modal_add_question.json";
import financial_adm from "./pages/_financial_adm.json";
import worker from "./pages/_worker.json";
import unit from "./pages/_unit.json";
import error from "./pages/_error.json";
import datasheet from "./pages/_datasheet.json";
import jobDetail from "./pages/_jobDetail.json";
import modal_datasheet from "./pages/_modal_datasheet.json";
import rating from "./pages/_rating.json";
import modal_report from "./pages/_modal_report.json";
import modal_emergency from "./pages/_modal_emergency.json";
import modal_updateOffer from "./pages/_modal_updateOffer.json";
import modal_activate_account from "./pages/_modal_activate_account.json";
import modal_freemium from "./pages/_modal_freemium.json";
import chat from "./pages/_chat.json";
import qrcode from "./pages/_qrcode.json";
import modal_search_statistics from "./pages/_modal_search_statistics.json";
import subaccounts from "./pages/_subaccounts.json";
import modal_join_subscription from "./pages/_modal_join_subscription.json";
import modal_subscription_problems from "./pages/_modal_subscription_problems.json";
import modalCreditCard from "./pages/_modalCreditCard.json";
import join_subscriptions from "./pages/_join_subscriptions.json";
import modal_limit_reached from "./pages/_modal_limit_reached.json";
import modal_update_payment_method from "./pages/_modal_update_payment_method.json";
import modal_cancel_subscription from "./pages/_modal_cancel_subscription.json";
import modal_delayed_invoice from "./pages/_modal_delayed_invoice.json";
import modal_cancel_details from "./pages/_modal_cancel_details.json";
import modal_invoice_detail from "./pages/_modal_invoice_detail.json";
import modal_contact from "./pages/_modal_contact.json";
import modal_financial_email from "./pages/_modal_financial_email.json";
import modal_whatsapp from "./pages/_modal_whatsapp.json";
import recruit from "./pages/_recruit.json";
import modal_register_vacancy from "./pages/_modal_register_vacancy.json";
import modal_add_pix from "./pages/_modal_add_pix.json";
import modal_payment_problem from "./pages/_modal_payment_problem.json";
import modal_selection_email from "./pages/_modal_selection_email.json";
import modal_transfer_balance from "./pages/_modal_transfer_balance";
import modal_receipt from "./pages/_modal_receipt.json";
import deposit_history from "./pages/_deposit_history.json";
import modal_finish_job from "./pages/_modal_finish_job.json";
import closeer_key from "./pages/_closeer_key.json";
import training_videos from "./pages/_training_videos.json";
import modal_trail_and_video from "./pages/_modal_trail_and_video.json";
import modal_question from "./pages/_modal_question.json";
import training_trail_details from "./pages/_training_trail_details.json";
import modal_trail_awaiting from "./pages/_modal_trail_awaiting.json";
import modal_job_and_search_resume from "./pages/_modal_job_and_search_resume.json";
import preferences_notification from "./pages/_preferences_notification.json";
import account_settings from "./pages/_account_settings.json";
import end_session from "./pages/_end_session.json";
import job_reason from "./pages/_job_reason.json";
import cost_center from "./pages/_cost_center.json";
import not_found from "./pages/_not_found.json";
import establishment_profile from "./pages/_establishment_profile.json";
import modal_address from "./pages/_modal_address.json";
import modal_job_settings from "./pages/_modal_job_settings.json";
import block_cpf from "./pages/_block_cpf.json";
import approvals from "./pages/_approvals.json";
import new_jobs from "./pages/_new_jobs.json";
import requests_jobs from "./pages/_requests_jobs.json";
import value_control from "./pages/_value_control.json";
import invite_account from "./pages/_invite_account.json";
import invalid_professionals from "./pages/_invalid_professionals.json";
import modal_report_approvals_and_disapprovals from "./pages/_modal_report_approvals_and_disapprovals.json";
import limits from "./pages/_limits.json";
import modal_limits from "./pages/_modal_limits.json";
import error_loading_module from "./pages/_error_loading_module.json";
import modal_multiple_approve_and_reprove from "./pages/_modal_multiple_approve_and_reprove.json";
import modal_limit_extra_value from "./pages/_modal_limit_extra_value.json";
import modal_extra_value from "./pages/_modal_extra_value.json";
import extra_value_card from "./pages/_extra_value_card.json";
import modal_block_unlock_worker from "./pages/_modal_block_unlock_worker.json";
import two_factor_authentication from "./pages/_two_factor_authentication.json";
import configJobStart from "./pages/_configJobStart.json";
import configJobEnd from "./pages/_configJobEnd.json";
import configJob from "./pages/_configJob.json";
import block_reasons from "./pages/_block_reasons.json";
import modal_extra_money from "./pages/_modal_extra_money.json";
import error_widget from "./pages/_error_widget.json";
import modal_score_details from "./pages/_modal_score_details.json";
import recruit_vacancy_detail from "./pages/_recruit_vacancy_detail.json";
import vacancies from "./pages/_vacancies.json";
import custom_roles from "./pages/_custom_roles.json";

export const es_ES = {
  /* ------------------------ */
  /* --------- TERMS -------- */
  /* ------------------------ */
  common: {
    ...common,
  },
  /* -------------------------------------- */
  /* --------- LABELS/PLACEHOLDERS -------- */
  /* -------------------------------------- */
  forms: {
    ...forms,
  },
  /* ---------------------------------------------- */
  /* --------- API RESP/TOASTS/FORM ERRORS -------- */
  /* ---------------------------------------------- */
  validation: {
    ...validation,
  },
  modules: {
    recurrence: {
      ...recurrenceCalendar,
    },
    notification_center: {
      ...notification_center,
    },
    links: {
      ...links,
    },
    legend: {
      ...legend,
    },
    subscription: {
      ...subscription,
    },
    custom_value_per_role: {
      ...custom_value_per_role,
    },
  },
  components: {
    dropzone: {
      ...dropzone,
    },
    enterScreenCards: {
      ...enterScreenCards,
    },
    filter: {
      ...filter,
    },
    modal_extra_value_report: {
      ...modal_extra_value_report,
    },
    search_nps: {
      ...search_nps,
    },
    modal_pro_rating_report: {
      ...modal_pro_rating_report,
    },
    modal_search_report: {
      ...modal_search_report,
    },
    balanceResume: {
      ...balanceResume,
    },
    modalConfigJobFinalization: {
      ...modalConfigJobFinalization,
    },
    modalConfigJobStart: {
      ...modalConfigJobStart,
    },
    modalConfigRecurrence: {
      ...modalConfigRecurrence,
    },
    modal_cancel_pending_worker_jobs: {
      ...modal_cancel_pending_worker_jobs,
    },
    modal_subscription_invalid: {
      ...modal_subscription_invalid,
    },
    modalPWA: {
      ...modalPWA,
    },
    modalCustomRole: {
      ...modalCustomRole,
    },
    modalBlockActions: {
      ...modalBlockActions,
    },
    modalPreCreateVacancy: {
      ...modalPreCreateVacancy,
    },
    modalVacancy: {
      ...modalVacancy,
    },
    modal_close_vacancy: {
      ...modal_close_vacancy,
    },
    modal_disqualified_candidates: {
      ...modal_disqualified_candidates,
    },
    modalBlockedWorkers: {
      ...modalBlockedWorkers,
    },
    modal_worker_other_unit: {
      ...modal_worker_other_unit,
    },
    modalRenewOffer: {
      ...modalRenewOffer,
    },
    modalSubscriptionError: {
      ...modalSubscriptionError,
    },
    modalExtendDepositExpiration: {
      ...modalExtendDepositExpiration,
    },
    modalChangeDepositValue: {
      ...modalChangeDepositValue,
    },
  },
  /* ------------------------ */
  /* --------- PAGES -------- */
  /* ------------------------ */
  login: {
    ...login,
  },
  create_account: {
    ...create_account,
  },
  forgot_password: {
    ...forgot_password,
  },
  new_password: {
    ...new_password,
  },
  dashboard: {
    ...dashboard,
  },
  modal_job: {
    ...modal_job,
  },
  professional: {
    ...professional,
  },
  search: {
    ...search,
  },
  eduCloseer: {
    ...eduCloseer,
  },
  report: {
    ...report,
  },
  billing: {
    ...billing,
  },
  configProfile: {
    ...configProfile,
  },
  configAddress: {
    ...configAddress,
  },
  configSideCards: {
    ...configSideCards,
  },
  instruction: {
    ...instruction,
  },
  units: {
    ...units,
  },
  access: {
    ...access,
  },
  user_access: {
    ...user_access,
  },
  jobs: {
    ...jobs,
  },
  schedule: {
    ...schedule,
  },
  modal_base_empty: {
    ...modal_base_empty,
  },
  modal_transaction_details: {
    ...modal_transaction_details,
  },
  modal_add_question: {
    ...modal_add_question,
  },
  financial_adm: {
    ...financial_adm,
  },
  worker: {
    ...worker,
  },
  unit: {
    ...unit,
  },
  error: {
    ...error,
  },
  datasheet: {
    ...datasheet,
  },
  jobDetail: {
    ...jobDetail,
  },
  modal_datasheet: {
    ...modal_datasheet,
  },
  rating: {
    ...rating,
  },
  modal_report: {
    ...modal_report,
  },
  modal_emergency: {
    ...modal_emergency,
  },
  modal_updateOffer: {
    ...modal_updateOffer,
  },
  modal_activate_account: {
    ...modal_activate_account,
  },
  modal_freemium: {
    ...modal_freemium,
  },
  chat: {
    ...chat,
  },
  qrcode: {
    ...qrcode,
  },
  modal_search_statistics: {
    ...modal_search_statistics,
  },
  subaccounts: {
    ...subaccounts,
  },
  modal_join_subscription: {
    ...modal_join_subscription,
  },
  modal_subscription_problems: {
    ...modal_subscription_problems,
  },
  modalCreditCard: {
    ...modalCreditCard,
  },
  join_subscriptions: {
    ...join_subscriptions,
  },
  modal_limit_reached: {
    ...modal_limit_reached,
  },
  modal_update_payment_method: {
    ...modal_update_payment_method,
  },
  modal_cancel_subscription: {
    ...modal_cancel_subscription,
  },
  modal_delayed_invoice: {
    ...modal_delayed_invoice,
  },
  modal_cancel_details: {
    ...modal_cancel_details,
  },
  modal_invoice_detail: {
    ...modal_invoice_detail,
  },
  modal_contact: {
    ...modal_contact,
  },
  modal_financial_email: {
    ...modal_financial_email,
  },
  modal_whatsapp: {
    ...modal_whatsapp,
  },
  modal_selection_email: {
    ...modal_selection_email,
  },
  recruit: {
    ...recruit,
  },
  modal_register_vacancy: {
    ...modal_register_vacancy,
  },
  modal_add_pix: {
    ...modal_add_pix,
  },
  modal_payment_problem: {
    ...modal_payment_problem,
  },
  modal_transfer_balance: {
    ...modal_transfer_balance,
  },
  modal_receipt: {
    ...modal_receipt,
  },
  deposit_history: {
    ...deposit_history,
  },
  modal_finish_job: {
    ...modal_finish_job,
  },
  closeer_key: {
    ...closeer_key,
  },
  training_videos: {
    ...training_videos,
  },
  modal_trail_and_video: {
    ...modal_trail_and_video,
  },
  modal_question: {
    ...modal_question,
  },
  training_trail_details: {
    ...training_trail_details,
  },
  modal_trail_awaiting: {
    ...modal_trail_awaiting,
  },
  modal_job_and_search_resume: {
    ...modal_job_and_search_resume,
  },
  preferences_notification: {
    ...preferences_notification,
  },
  account_settings: {
    ...account_settings,
  },
  end_session: {
    ...end_session,
  },
  job_reason: {
    ...job_reason,
  },
  cost_center: {
    ...cost_center,
  },
  not_found: {
    ...not_found,
  },
  modal_address: {
    ...modal_address,
  },
  modal_job_settings: {
    ...modal_job_settings,
  },
  establishment_profile: {
    ...establishment_profile,
  },
  block_cpf: {
    ...block_cpf,
  },
  approvals: {
    ...approvals,
  },
  new_jobs: {
    ...new_jobs,
  },
  requests_jobs: {
    ...requests_jobs,
  },
  value_control: {
    ...value_control,
  },
  modal_report_approvals_and_disapprovals: {
    ...modal_report_approvals_and_disapprovals,
  },
  limits: {
    ...limits,
  },
  modal_limits: {
    ...modal_limits,
  },
  invalid_professionals: {
    ...invalid_professionals,
  },
  error_loading_module: {
    ...error_loading_module,
  },
  modal_multiple_approve_and_reprove: {
    ...modal_multiple_approve_and_reprove,
  },
  modal_limit_extra_value: {
    ...modal_limit_extra_value,
  },
  modal_extra_value: {
    ...modal_extra_value,
  },
  extra_value_card: {
    ...extra_value_card,
  },
  modal_block_unlock_worker: {
    ...modal_block_unlock_worker,
  },
  two_factor_authentication: {
    ...two_factor_authentication,
  },
  configJobStart: {
    ...configJobStart,
  },
  configJobEnd: {
    ...configJobEnd,
  },
  configJob: {
    ...configJob,
  },
  block_reasons: {
    ...block_reasons,
  },
  modal_extra_money: {
    ...modal_extra_money,
  },
  error_widget: {
    ...error_widget,
  },
  modal_score_details: {
    ...modal_score_details,
  },
  recruit_vacancy_detail: {
    ...recruit_vacancy_detail,
  },
  vacancies: {
    ...vacancies,
  },
  custom_roles: {
    ...custom_roles,
  },
};
